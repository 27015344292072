<template>
  <div class="news-detail-page">
    <Nav />
    <div class="top-bg">
      <img :src="newsInfo.cover_image" alt="" />
    </div>
    <div class="top-content">
      <h3>{{ newsInfo.title }}</h3>
      <p class="date">{{ formatDate(newsInfo.news_time) }}</p>
      <div class="v-html-content" v-html="newsInfo.content"></div>
      <!-- <p class="foreword">
        埃斯佩尔坎普/海阳,
        2023年11月3日—迄今为止，浩亭家族企业在全球已开设了44家子公司和14家生产工厂。如今，浩亭在全球将增加新的生产基地，即日起，连接器和预组装解决方案将遵循浩亭质量标准在越南海阳开始生产。
      </p>
      <p class="text">
        浩亭现已在越南建立了新的生产基地，
        地理位置邻近中国，越南是浩亭技术集团在亚洲具有重要战略意义的国家。即日起，经过专业培训的核心团队将在超过2,500平方米的工厂内启动生产。
      </p>
      <p class="text">
        “确保浩亭在越南生产产品的高质量标准对我们而言同样重要，”浩亭技术集团董事会成员Andreas
        Conrad表示，“凭借浩亭全球标准化流程和生产设施，我们向全球客户保证越南生产产品将保持一如既往的高质量。无论是在德国、罗马尼亚、墨西哥还是越南——我们的客户都可以信赖浩亭的产品质量。
        ”
      </p>
      <p class="text">
        技术集团首席执行官Philip
        Harting亲自到现场为新生产工厂揭幕。“我们在越南新收购了基地，我们正在东南亚经济增长区域建立一个重要的里程碑。在越南海阳建厂，我们更贴近客户并直接在现场生产。我们正在将运输路程缩至最短，并以这种方式记录二氧化碳减排的重要意义。与管理团队一起，我们为浩亭的下一步扩张确定了方向。”
      </p>
      <div class="card-list">
        <div class="card">
          <img src="../../image/word-news-detail01.png" alt="" />
          <div class="brief">
            莅临浩亭越南工厂开幕仪式的有: 浩亭越南和浩亭珠海制造公司总经理Marcus
            Göttig先生，德国驻河内大使馆经济与发展合作专员Alexandra
            Westwood女士，浩亭技术集团首席执行官Philip
            Harting先生，海阳工业区管理委员会副主席Nguyễn Thị Thúy
            Hằng女士，浩亭技术集团董事会专职运营成员Andreas
            Conrad先生(从左至右)。
          </div>
        </div>
        <div class="card">
          <img src="../../image/word-news-detail02.png" alt="" />
          <div class="brief">
           仪式以正式剪彩结束
          </div>
        </div>
      </div> -->
      <div
        class="doc-list"
        v-if="documents && documents.length"
      >
        <div
          v-for="(item, index) in documents"
          :key="index"
          class="doc"
        >
          <div class="pdf-icon"></div>
          <div class="doc-name">文档链接:</div>
          <div class="down-icon">
            <a :href="item.url">
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
      <div class="common-bottom-empty"></div>
    </div>
  </div>
</template>

<script>
import request from "../../utils/request";
import dayjs from "dayjs"; // 导入封装好的axios实例
import Nav from "../../component/nav.vue";
export default {
  name: "newsdetail",
  components: {
    Nav,
  },
  data() {
    return {
      newsInfo: {}, // 将newsList初始化为空数组
      documents: []
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD HH:mm");
    },
    // 获取新闻列表数据
    fetchNewsInfo(id) {
      request
        .get("/news/" + id) // 假设接口地址为 /news
        .then((response) => {
          this.newsInfo = response; // 将接口返回的数据赋值给newsList
          if(response.documents && response.documents.length > 0) {
            let arr = response.documents.map(item => {
              return {
                url: item,
                name: this.extractFileNameFromUrl(item)
              }
            })
            this.documents = arr
          }
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    extractFileNameFromUrl(url) {
      // 使用最后一个'/'来分割URL
      const parts = url.split("/");
      // 获取最后一个部分，它通常是文件名
      const filename = parts[parts.length - 1];
      return filename;
    },
  },
  created() {
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchNewsInfo(id);
  },
};
</script>
<style lang="less" scoped>
.news-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #cdd7de;
  .top-bg {
    position: relative;
    width: 100%;
    height: 160px;
    // background: url("../../image/word-news-detail.png") no-repeat center center /
    //   cover;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .top-content {
    position: relative;
    box-sizing: border-box;
    padding: 40px 32px;
    width: 100%;
    min-height: 500px;
    border-radius: 40px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
  }
  h3 {
    margin-bottom: 8px;
    color: #212027;
    /* 3级标题 */
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 28.8px */
  }
  .date {
    margin-bottom: 32px;
    color: #9c9d9d;
    /* 辅助-时间 12 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }
  .foreword {
    margin-bottom: 16px;
    color: #212027;
    /* 内文 加粗 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
  }
  .text {
    margin-bottom: 16px;
    color: #535359;

    /* 内文 常规 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
  }
  // .card-list {
  //   padding: 16px 0 50px 0;

  //   .card {
  //     margin-bottom: 32px;
  //     border-radius: 24px;
  //     background: #fff;
  //     width: 100%;
  //     min-height: 262px;
  //     overflow: hidden;
  //   }
  //   img {
  //     display: block;
  //     width: 100%;
  //     height: 200px;
  //   }
  //   .brief {
  //     padding: 16px 16px 24px 16px;
  //     color: #535359;
  //     font-size: 14px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 160%; /* 22.4px */
  //     background: #f0f3f4;
  //   }
  // }
  .doc-list {
    font-size: 16px;
    .doc-name {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }
    a {
      display: block;
      color: #000;
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 14px;
      background: #ffcc00;
    }
  }
}
</style>
<style lang="less">
.v-html-content {
  a {
    position: relative;
    padding: 12px 44px 12px 12px;
    background: #ffcc00;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    white-space: nowrap; /* 防止内容换行 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 超出部分显示为省略号 */
  }
  a:after {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    content: "→";
  }
}
</style>
